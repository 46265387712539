<template>
    <div class="default-headerBar">
        <div class="Logo">
            <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20231204/4dc90a956a0220ffe745a29543b16e6a.png" alt=""> 
            有库云服务端
        </div>
        <div class="tool-bar">
            <div class="service">UKOO Cloud Management backend</div>
            <div class="tb-wrap">
                <!-- <div class="shop-wrap">
                    <img class="img" src="https://ukoo.oss-cn-beijing.aliyuncs.com/system/20231204/314f0f265f6f80ac270acac06aa59549.png" alt=""> 
                    <div class="name">{{userInfo.company_name}}</div>
                </div> -->
                <div class="logout">
                    <div class="off" @click="isEditShow = true"><i class="el-icon-lock"></i> 修改密码</div>
                    <div class="off" @click="handleLogouts"><i class="el-icon-switch-button"></i> 退出登录</div>
                </div>
            </div>
        </div>
        <!-- 添加 -->
        <el-dialog
            :visible.sync="isEditShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px">
            <div class="class-wrap">
                <div class="cw-title">修改密码</div>
                <div class="cw-list">
                    <el-input v-model="password" placeholder="请输入新密码"></el-input>
                </div>
                <div class="cw-list">
                    <el-input v-model="isPassword" placeholder="请再次确认密码"></el-input>
                </div>
                <div class="cw-btn" @click="clickAddMain">确定</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
export default {
    data() {
        return {
            userInfo: {},
            username: '',
            name: '',
            head: '',
            kfInfo: '',
            isShow: false,
            endTime: false,
            isEditShow: false,
            password: '',
            isPassword: '',
        }
    },
    mounted(){
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    },
    methods: {
        // 修改密码
        clickAddMain(){
            if(this.isPassword != this.password){
                this.$message.error('两次密码输入不相同')
                return
            }
            let params = {
                password: this.password
            }
            api.post('/service/service/set_pwd', params, (res) =>  {
                this.isEditShow = false
                this.$message.success('修改成功，请重新登录')
                localStorage.clear()
                location.href="/";   
            });
        },
        handleLogouts(){
            this.$confirm('将退出当前账号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                api.post('/service/login/logout', {}, (res) =>  {
                    localStorage.clear()
                    //要使用location强制跳转
                    location.href="/";   
                });
            });
        }
    },
    computed: {
        adAccountName(){
            return this.$store.state.adAccountName
        }
    }
}
</script>

<style lang="scss">
.default-headerBar{
    width: 100%;
    height: 60px;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 9996;
    background-color: #062552;
    .Logo{
        width: 250px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        background-color: #062552; 
        color: $logoColor;
        .img{
            height: 31px;
            width: 30px;
            margin-right: 10px;
        }
    }
    .tool-bar{
        width: calc(100% - 250px);
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #062552;
        border-left: 1px solid #fff;
        .service{
            margin-left: 40px;
            font-size: 15px;
            color: #fff;
        }
        .tb-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .shop-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-right: 100px;
                .img{
                    width: 20px;
                    height: 20px;
                    vertical-align: bottom;
                }
                .name{
                    font-size: 14px;
                    color: #fff;
                    margin-left: 10px;
                }
            }
        }
        .logout{
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 20px;
            padding-right: 20px;
            font-size: 15px;
            cursor: pointer;
            .off{
                font-size: 15px;
                padding: 2px;
                color: #fff;
                font-weight: bold;
                margin-left: 30px;
                .el-icon-switch-button{
                    font-weight: bold;
                    font-size: 16px;
                }
                .el-icon-lock{
                    font-weight: bold;
                    font-size: 16px;
                }
            }
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            padding-left: 20px;
        }
        .cw-list{
            padding: 20px 20px 0 20px;
        }
        .cw-btn{
            width: 100px;
            height: 40px;
            border-radius: 4px;
            font-size: 15px;
            color: #fff;
            background: #0068ff;
            margin: 20px auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
}
</style>