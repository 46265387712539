<template>
    <div class="default-menuBar">
        <el-menu :default-active="curretMenuIndex" router :unique-opened="true" @select="selectIndex">
            <template v-for="(item) in menuList">
                <el-submenu :index="item.index" v-if='item.sbuMenu' :key="item.id">
                    <template slot="title">
                        <!-- <i :class="item.icon"></i> -->
                        <span>{{ item.title }}</span>
                    </template>
                    <div v-for="(subItem, subindex) in item.subs" :key="subindex">
                        <el-menu-item-group v-if="subItem.sbuMenu">
                            <el-menu-item :key="subindex" :index="subItem.index"
                                style="padding-left:55px">{{ subItem.title }}</el-menu-item>
                        </el-menu-item-group>
                    </div>
                </el-submenu>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import api from "@/utils/common.js"
export default {
    data() {
        return {
            menuList: [],
            defaultActive: "",
            userInfo: {}
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.menuList = this.$menu.storeMenu;
        this.getUserInfo()
        
    },
    computed: {
        curretMenuIndex() {
            return this.$store.state.curretMenuIndex
        }
    },
    methods: {
        getUserInfo(){
            api.get('/service/service/get_info', {}, (res) =>  {
                localStorage.setItem('userInfo', JSON.stringify(res.data.service));
                this.userInfo = res.data.service
                if(this.menuList){
                    this.menuList.forEach(v => {
                        v.subs.forEach(v1 => {
                            if(this.userInfo.is_admin == 1){
                                v1.sbuMenu = true
                            }else{
                                v1.sbuMenu = false
                                if (v1.title == '用户服务列表' || v1.title == '行业分类管理') {
                                    v1.sbuMenu = true
                                }
                            }
                        })
                    })
                }
            });
        },
        ...mapMutations(['setCurretMenuIndex']),
        selectIndex(index) {
            this.setCurretMenuIndex(index)
        },
    }
}
</script>

<style lang="scss">
.default-menuBar {
    width: 250px;
    height: 100%;
    box-sizing: border-box;
    z-index: 9997;
    background-color: #062552;
    .profile-wrap {
        height: 220px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        .profile {
            box-sizing: border-box;

            img {
                border: 1px solid #E8E8E8;
                border-radius: 50px;
                padding: 3px 3px;
                box-sizing: border-box;
                width: 80px;
                height: 80px;
                box-shadow: 0px 0px 10px #ccc;
            }

            .wechat-info {
                margin-top: 8px;
                text-align: center;

                .name {
                    font-weight: 600;
                    color: #2C2C2C;
                }

                .welcome {
                    margin-top: 5px;
                    font-size: 12px;
                    color: #9B9B9B;
                }
            }
        }

        &::after {
            content: '';
            position: absolute;
            height: 1px;
            width: 85%;
            background-color: #fff;
            bottom: 0;
            left: 20px;
        }
    }

    .el-menu {
        width: 100%;
        height: calc(100% - 220px);
        border-right: none;
        text-align: left;
        box-sizing: border-box;
        background-color: #062552;
        .iconfont {
            margin-right: 15px;
            font-size: 18px;
            font-weight: 500;
            color: #fff;
        }
        .el-menu-item {
            color: #fff;
            font-size: 15px;
        }
        .el-menu-item-group__title{
            padding: 0 !important;
        }
        .el-submenu {
            .el-submenu__title {
                // background-color: #0770ff;
                font-size: 15px;
                color: #fff;
                i {
                    color: #fff;
                }
            }
            .el-menu-item {
                font-size: 14px;
                color: #fff;
            }
        }
        .el-menu-item:hover {
            background-color: #064aa9;
            color: #fff;
            .iconfont {
                color: #fff;
            }
        }
        .el-submenu__title:hover {
            background-color: #0770ff;
            color: #fff;
            .iconfont {
                color: #fff;
            }
        }
        .is-active {
            background-color: #0770ff;
            color: #fff;
            .iconfont {
                color: #fff;
            }
        }
        .el-menu-item:focus {
            color: #fff;
            background-color: #064aa9;
        }
        .el-menu-item.is-active {
            color: #fff;
            background-color: #064aa9;
            i {
                color: #fff;
            }
        }
        .el-submenu.is-active>.el-submenu__title {
            color: #fff;
            i {
                color: #fff;
            }
        }
    }
}</style>